:root {
    --markdown-padding: 6px 13px;
    --markdown-border-color: black;
  }
  
  .markdown {
    /* Existing styles */
    table {
      border-collapse: collapse;
    }
  
    th,
    td {
      padding: var(--markdown-padding);
      border: 1px solid var(--markdown-border-color);
    }
  
    p {
      line-height: 1.5;
    }
  
    /* New styles to improve text rendering */
    font-feature-settings: "liga" 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* Ensure inline code blocks preserve whitespace and special characters */
    code {
      font-family: 'Courier New', Courier, monospace;
      white-space: pre-wrap;
      word-break: break-word;
    }
  
    /* Improve readability for inline code */
    :not(pre) > code {
      padding: 0.2em 0.4em;
      background-color: rgba(27, 31, 35, 0.05);
      border-radius: 3px;
    }
  }